

























import { Component, Vue } from 'vue-property-decorator';
import { HeaderBar } from '@cloudpivot7-business-components/pc';
import { H3Icon } from '@authine/awesome-ui';

@Component({
  name: 'WorkPlatFormHome',
  components: {
    HeaderBar,
    AIcon: H3Icon,
  },
})
export default class WorkPlatFormHome extends Vue {
  get showLogo () {
    const routeName: any = this.$route.name;

    if (routeName === 'flow-center') {
      return false;
    }

    return true;
  }

  goBack () {
    this.$router.push({
      name: 'work-platform-home',
    });
  }
}
